var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClickTab },
            model: {
              value: _vm.activeTabName,
              callback: function ($$v) {
                _vm.activeTabName = $$v
              },
              expression: "activeTabName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "Cấu hình điểm danh", name: "attendance" } },
              [
                _c("div", { staticClass: "wrapper-table" }, [
                  _c(
                    "table",
                    { staticClass: "table-data", attrs: { border: "" } },
                    [
                      _c("thead", { staticClass: "table-header" }, [
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("STT"),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Nội dung")]
                          ),
                          _c("td", { staticClass: "table-action" }, [
                            _vm._v("Thiết lập"),
                          ]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("1"),
                          ]),
                          _c("td", [_vm._v("Mặc định có làm sáng không?")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.morning,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "morning",
                                        $$v
                                      )
                                    },
                                    expression: "configSchoolCommon.morning",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("2"),
                          ]),
                          _c("td", [_vm._v("Mặc định có làm chiều không?")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.afternoon,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "afternoon",
                                        $$v
                                      )
                                    },
                                    expression: "configSchoolCommon.afternoon",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("3"),
                          ]),
                          _c("td", [_vm._v("Mặc định có làm tối không?")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.evening,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "evening",
                                        $$v
                                      )
                                    },
                                    expression: "configSchoolCommon.evening",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("4"),
                          ]),
                          _c("td", [
                            _vm._v("Mặc định có làm sáng thứ 7 không?"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.configSchoolCommon.saturdayMorning,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "saturdayMorning",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configSchoolCommon.saturdayMorning",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("5"),
                          ]),
                          _c("td", [
                            _vm._v("Mặc định có làm chiều thứ 7 không?"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.configSchoolCommon.saturdayAfternoon,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "saturdayAfternoon",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configSchoolCommon.saturdayAfternoon",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("6"),
                          ]),
                          _c("td", [
                            _vm._v("Mặc định có làm tối thứ 7 không?"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.configSchoolCommon.saturdayEvening,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "saturdayEvening",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configSchoolCommon.saturdayEvening",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("7"),
                          ]),
                          _c("td", [
                            _vm._v("Mặc định có làm sáng chủ nhật không?"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.sundayMorning,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "sundayMorning",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configSchoolCommon.sundayMorning",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("8"),
                          ]),
                          _c("td", [
                            _vm._v("Mặc định có làm chiều chủ nhật không?"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.configSchoolCommon.sundayAfternoon,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "sundayAfternoon",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configSchoolCommon.sundayAfternoon",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("9"),
                          ]),
                          _c("td", [
                            _vm._v("Mặc định có làm tối chủ nhật không?"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.sundayEvening,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "sundayEvening",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configSchoolCommon.sundayEvening",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("10"),
                          ]),
                          _c("td", [_vm._v("Mặc định có ăn bữa sáng không?")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.breakfast,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "breakfast",
                                        $$v
                                      )
                                    },
                                    expression: "configSchoolCommon.breakfast",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("11"),
                          ]),
                          _c("td", [_vm._v("Mặc định có ăn bữa trưa không?")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.lunch,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "lunch",
                                        $$v
                                      )
                                    },
                                    expression: "configSchoolCommon.lunch",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("12"),
                          ]),
                          _c("td", [_vm._v("Mặc định có ăn bữa tối không?")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.configSchoolCommon.dinner,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configSchoolCommon,
                                        "dinner",
                                        $$v
                                      )
                                    },
                                    expression: "configSchoolCommon.dinner",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Có"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("Không"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _vm.checkPermission([
                      "schoolConfig_attendanceEmployee_update",
                    ])
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "border-radius": "0" },
                            attrs: { size: "medium", type: "success" },
                            on: { click: _vm.settingDayOffEmployee },
                          },
                          [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _c("span", [_vm._v("Tạo ngày nghỉ chung")]),
                          ]
                        )
                      : _vm._e(),
                    _vm.checkPermission([
                      "schoolConfig_attendanceEmployee_update",
                    ])
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "border-radius": "0" },
                            attrs: {
                              loading: _vm.loaddingButton,
                              size: "medium",
                              type: "success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitConfigCommon()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-circle-check" }),
                            _c("span", [_vm._v("Lưu")]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "Cấu hình thời gian", name: "configTime" } },
              [
                _c("div", { staticClass: "wrapper-table" }, [
                  _c(
                    "table",
                    { staticClass: "table-data", attrs: { border: "" } },
                    [
                      _c("thead", { staticClass: "table-header" }, [
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("STT"),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Nội dung")]
                          ),
                          _c("td", { staticClass: "table-action" }, [
                            _vm._v("Thiết lập"),
                          ]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("1"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n                  Thiết lập giờ đi làm buổi sáng của nhân viên nhà trường?\n                "
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  size: "mini",
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "06:00:00 - 21:00:00",
                                  },
                                  placeholder: "Chọn",
                                },
                                model: {
                                  value:
                                    _vm.configSchoolTime.timeMorningEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configSchoolTime,
                                      "timeMorningEmployee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configSchoolTime.timeMorningEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("1"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n                  Thiết lập giờ đi làm buổi chiều của nhân viên nhà trường?\n                "
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  size: "mini",
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "06:00:00 - 21:00:00",
                                  },
                                  placeholder: "Chọn",
                                },
                                model: {
                                  value:
                                    _vm.configSchoolTime.timeAfternoonEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configSchoolTime,
                                      "timeAfternoonEmployee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configSchoolTime.timeAfternoonEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("1"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n                  Thiết lập giờ đi làm buổi tối của nhân viên nhà trường?\n                "
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  size: "mini",
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "06:00:00 - 21:00:00",
                                  },
                                  placeholder: "Chọn",
                                },
                                model: {
                                  value:
                                    _vm.configSchoolTime.timeEveningEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configSchoolTime,
                                      "timeEveningEmployee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configSchoolTime.timeEveningEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("2"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Thiết lập giờ về ca sáng của nhân viên nhà trường?"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  size: "mini",
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "06:00:00 - 21:00:00",
                                  },
                                  placeholder: "Chọn",
                                },
                                model: {
                                  value:
                                    _vm.configSchoolTime
                                      .timeLeaveMorningEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configSchoolTime,
                                      "timeLeaveMorningEmployee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configSchoolTime.timeLeaveMorningEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("2"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Thiết lập giờ về ca chiều của nhân viên nhà trường?"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  size: "mini",
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "06:00:00 - 21:00:00",
                                  },
                                  placeholder: "Chọn",
                                },
                                model: {
                                  value:
                                    _vm.configSchoolTime
                                      .timeLeaveAfternoonEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configSchoolTime,
                                      "timeLeaveAfternoonEmployee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configSchoolTime.timeLeaveAfternoonEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("2"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Thiết lập giờ về ca tối của nhân viên nhà trường?"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  size: "mini",
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "06:00:00 - 21:00:00",
                                  },
                                  placeholder: "Chọn",
                                },
                                model: {
                                  value:
                                    _vm.configSchoolTime
                                      .timeLeaveEveningEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configSchoolTime,
                                      "timeLeaveEveningEmployee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configSchoolTime.timeLeaveEveningEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  [
                    _vm.checkPermission([
                      "schoolConfig_attendanceEmployee_update",
                    ])
                      ? _c(
                          "el-button",
                          {
                            staticClass: "button-click",
                            attrs: {
                              loading: _vm.loaddingButton,
                              size: "medium",
                              type: "success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitConfigTime()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-circle-check" }),
                            _c("span", [_vm._v("Lưu")]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("DayOffEmployeeDialog", {
          ref: "DayOffEmployeeDialog",
          attrs: { dialogVisible: _vm.showDayOffEmployee },
          on: { "dialog-close": _vm.closeSettingDayOffMethod },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }