<template>
  <el-dialog
      :title="'Thiết lập ngày nghỉ nhân viên: ' + className"
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      @open="handleOpen"
      top="3vh"
  >
    <div>
      <spinner-custom v-if="showSpinner" />
      <br />
      <el-form
          label-width="140px"
          :model="dataInput"
          label-position="left"
          :rules="rules"
          ref="dataInput"
      >
        <el-form-item label="Chọn ngày" prop="dateList">
          <el-date-picker
              style="margin: 0 auto; width: 100%"
              type="dates"
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
              v-model="dataInput.dateList"
              placeholder="Chọn các ngày nghỉ"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Ghi chú" prop="note">
          <el-input v-model="dataInput.note" placeholder="Ghi chú"> </el-input>
        </el-form-item>
      </el-form>
      <span style="float: right; margin-bottom: 15px">
          <el-button
              type="success"
              size="medium"
              :loading="loadingButton"
              mini
              @click="submitForm()"
          >
            <i class="el-icon-circle-check" />
            <span>Tạo ngày nghỉ</span>
          </el-button>
        </span>
      <hr style="clear: right" />
      <div style="margin-bottom: 5px">
        <el-date-picker
            style="margin-right: 5px"
            v-model="dataSearch.year"
            type="year"
            :clearable="false"
            placeholder="Chọn năm"
            @change="getDayOffEmployeeYearMethod()"
        >
        </el-date-picker>
        <el-date-picker
            style="margin-right: 5px"
            v-model="dataSearch.date"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            placeholder="Chọn ngày"
            @change="getDayOffEmployeeYearMethod()"
        >
        </el-date-picker>
        <el-input
            style="width: 300px"
            placeholder="Nhập ghi chú"
            clearable
            v-model="dataSearch.note"
            @clear="getDayOffEmployeeYearMethod()"
            @keyup.enter.native="getDayOffEmployeeYearMethod()"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="getDayOffEmployeeYearMethod()"
          ></el-button>
        </el-input>
      </div>
      <el-table
          ref="multipleTable"
          :empty-text="textTable"
          highlight-current-row
          :data="responseTab2List"
          :header-cell-style="tableHeaderColor"
          :max-height="350"
          @selection-change="handleSelectionChange"
          border
      >
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column align="center" width="200" label="Ngày nghỉ">
          <template slot-scope="scope">
            <span>{{ scope.row.date | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Ghi chú">
          <template slot-scope="scope">
            <el-input v-model="scope.row.note"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Tác vụ" fixed="right" width="170" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="updateRowMethod(scope.row)"
            >Sửa</el-button
            >
            <el-button type="danger" size="mini" @click="deleteRowMethod(scope.row)"
            >Xóa</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     <div class="buttons">
       <el-button type="warning" size="medium" @click="deleteManyMethod()">
         <i class="el-icon-delete" />
         <span>{{ $t("button.delete") }}</span>
       </el-button>
       <el-button type="danger" size="medium" @click="closeDialog()">
         <i class="el-icon-circle-close" />
         <span>{{ $t("button.close") }}</span>
       </el-button>
     </div>
    </div>
  </el-dialog>
</template>

<script>
import checkPermission from "@/utils/permission.js";
import SchoolConfigService from "@/services/SchoolService/SchoolConfigService";
export default {
  name: "DayOffEmployeeDialog",
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      showSpinner: false,
      textTable: "",
      className: "",
      dataSearch: {
        year: this.moment(),
        date: "",
        note: "",
      },
      responseTab1List: [],
      responseTab2List: [],
      multipleSelection: [],
      dataInput: {
        dateList: [],
        note: "Ngày nghỉ",
      },
      loadingButton: false,
      rules: {
        dateList: [
          {
            required: true,
            message: "Ngày không được để trống",
            trigger: "change",
          },
        ],
        note: [
          {
            required: true,
            message: "Ghi chú không được để trống",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    checkPermission,
    handleOpen(){
      this.getDayOffEmployeeYearMethod();
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.dataSearch.year = this.moment()
        this.dataSearch.date = ""
        this.dataInput.dateList = []
        this.responseTab1List = [];
        this.responseTab2List = [];
        this.loadingButton = false;
        this.$refs["dataInput"].resetFields();
      }, 300);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    updateRowMethod(row) {
      const idDayOffEmploy = row.id
      const note = row.note
      SchoolConfigService.updateDayOffEmployeeYear(idDayOffEmploy,note)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
          })
          .catch((err) => {
            this.responseTab2List = [];
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    deleteRowMethod(row) {
      this.$confirm("Bạn có chắc chắn muốn xóa không?", "Thông báo!", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Có",
        closeOnClickModal: false,
        cancelButtonText: "Không",
      }).then(() => {
        SchoolConfigService.deleteDayOffEmployeeYear(row.id)
            .then((resp) => {
              this.$message({
                message: resp.data.message,
                type: "success",
              });
              this.getDayOffEmployeeYearMethod();
            })
            .catch((err) => {
              this.responseTab2List = [];
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            });
      });
    },
    deleteManyMethod() {
      let listId = this.multipleSelection.map((x) => x.id);
      if (listId.length === 0) {
        this.$message({
          message: "Chưa có hàng nào được chọn",
          type: "error",
        });
        throw null;
      }
      this.$confirm("Bạn có chắc chắn muốn xóa không?", "Thông báo!", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Có",
        closeOnClickModal: false,
        cancelButtonText: "Không",
      }).then(() => {
        SchoolConfigService.deleteDayOffEmployeeYearList(listId)
            .then((resp) => {
              this.$message({
                message: resp.data.message,
                type: "success",
              });
              this.getDayOffEmployeeYearMethod();
            })
            .catch((err) => {
              this.responseTab2List = [];
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            });
      });
    },

    submitForm() {
      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.dataInput.dateList = this.dataInput.dateList.join(',')
          SchoolConfigService.createDayOffEmployee(this.dataInput)
              .then((resp) => {
                this.$message({
                  message: resp.data.message,
                  type: "success",
                });
                this.getDayOffEmployeeYearMethod();
              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
                this.$refs["dataInput"].resetFields();
              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 500);
              });
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },
    getDayOffEmployeeYearMethod() {
      if (this.dataSearch.date == null) {
        this.dataSearch.date = "";
      }
      const param = {
        year: this.moment(this.dataSearch.year).year(),
        date: this.dataSearch.date,
        note:  this.dataSearch.note
      }
      SchoolConfigService.getDayOffEmployeeYear(param)
      .then((resp) => {
        this.responseTab2List = resp.data;
        console.log(this.responseTab2List)
      })
      .catch((err) => {
        this.responseTab2List = [];
        this.$message({
          message: err.response.data.message,
          type: "error",
        });
      })
      .finally(() => {
        if (this.responseTab2List.length == 0) {
          this.textTable = this.$tableEmpty;
        }
      });
    },
    getDayOffClassViewMethod() {
      SchoolConfigService.viewDayOffClass(this.dataInput.idClass)
          .then((resp) => {
            this.responseTab1List = resp.data.data;
            this.showSpinner = false;
          })
          .catch((err) => {
            this.responseTab1List = [];
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    getDataInitial(row) {
      this.className = row.className;
      this.dataInput.idClass = row.id;
      this.getDayOffClassViewMethod();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}
/deep/.el-dialog__body {
  padding-bottom: 5px;
}

/deep/.el-calendar-table .el-calendar-day {
  font-size: 20px;
  text-align: center;
  height: 65px;
  background: lightgrey;
}
.mark {
  font-size: 15px;
  color: white;
  background: #e74b4b;
  height: 33px;
  overflow: hidden;
  line-height: 33px;
  word-break: break-word;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 15px;
}
</style>
